import "./App.css";
import AboutSection from "./components/aboutSection";
import Header from "./components/header";
import HeroSection from "./components/heroSection";
import VideoSection from "./components/videoSection";

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <AboutSection />
      <VideoSection />
    </div>
  );
}

export default App;
