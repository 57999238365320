import { useState } from "react";
import { MdPlayCircleOutline } from "react-icons/md";

import videoURLs from "../utils/videoURLs";
import Video from "./video";

function VideoSection() {
  const [video, setVideo] = useState(videoURLs[0]);
  const [selected, setSelected] = useState(0);
  function changeVideo(i) {
    setVideo(videoURLs[i]);
    setSelected(i);
  }
  return (
    <section id="video">
      <Video url={video.url} title={video.title} />
      <ul id="video-list">
        {videoURLs.map((video, i) => (
          <li
            className={`video-list__item ${selected === i ? "selected" : ""}`}
            key={video.title}
            onClick={() => {
              changeVideo(i);
            }}
          >
            <MdPlayCircleOutline
              color={selected === i ? "#111" : "#aaa"}
              size="24px"
            />
            <span>{video.title}</span>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default VideoSection;
