import { useLayoutEffect, useRef } from "react";

function Video({ url, title }) {
  const iFrame = useRef();
  useLayoutEffect(() => {
    const setVideoDimentions = () => {
      const w = window.innerWidth > 1024 ? 1024 : window.innerWidth;
      iFrame.current.setAttribute("width", w);
      iFrame.current.setAttribute("height", w * 0.5625);
    };

    setVideoDimentions();

    window.addEventListener("resize", setVideoDimentions);
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", setVideoDimentions);
    }
    return () => {
      window.removeEventListener("resize", setVideoDimentions);
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener(
          "change",
          setVideoDimentions
        );
      }
    };
  }, []);

  return (
    <div id={title}>
      <iframe
        ref={iFrame}
        src={url}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
export default Video;
