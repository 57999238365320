const videoURLs = [
  {
    title: "Redwood",
    url: "https://www.youtube.com/embed/1_2a9T21_Tc",
  },
  {
    title: "Vertabrae",
    url: "https://www.youtube.com/embed/9jkMnOffO_k",
  },
  {
    title: "Silly Putty",
    url: "https://www.youtube.com/embed/Dl7uBzRx2zI",
  },
  {
    title: "Redwood",
    url: "https://www.youtube.com/embed/ErEO7JGwIi0",
  },
  {
    title: "For all Intensive Purposes",
    url: "https://www.youtube.com/embed/xmWF0gfqMeE",
  },
  {
    title: "Vertabrae > Low Spark",
    url: "https://www.youtube.com/embed/1sNMcHRuuI4",
  },
  {
    title: "Measure it with the Chicken",
    url: "https://www.youtube.com/embed/phz5cpr2Hsc",
  },
  {
    title: "Sauced > Eyes of the World",
    url: "https://www.youtube.com/embed/TIzOeCY9-gs",
  },
];

export default videoURLs;
