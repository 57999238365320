import fullBandLg from "../img/full_band_lg.jpeg";
import fullBandSm from "../img/full_band_sm.jpeg";

function HeroSection() {
  return (
    <section id="hero" className="flex justifyCenter">
      <img
        id="hero-img"
        src={fullBandLg}
        alt="Flight 467"
        srcSet={`${fullBandSm} 720w, ${fullBandLg} 1440w`}
        sizes="(max-width: 600px) 720px, 1440px"
      />
    </section>
  );
}

export default HeroSection;
