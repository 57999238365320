import { useEffect, useRef, useState } from "react";

function AboutSection() {
  const about = useRef();
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowing(true);
          }
        });
      },
      {
        threshold: 0.25,
      }
    );
    observer.observe(about.current);

    return () => {
      observer.disconnect();
    };
  }, [showing]);
  return (
    <section id="about" className="flex justifyCenter alignCenter">
      <p className={`info ${showing ? "showing" : ""}`} ref={about}>
        <strong>Flight 467</strong> is a virtuosic NYC-based funk-fusion band.
        Their sound is centered around <strong>Adam Strum</strong> (guitar) and{" "}
        <strong>Josh Geisler</strong> (bass), who have been playing together for
        over 25 years and share a telepathic level of improvisational
        communication. Adding their formidable talents to the mix are veteran
        pro musicians <strong>Doron Lev</strong> (drums),{" "}
        <strong>Sean Nowell </strong>
        (tenor sax), and <strong>Phil Kester</strong> (drums and percussion). A
        Flight 467 show brings a potent combination of tight, funky compositions
        and free-flowing improvisational journeys that leave their audience
        inspired and coming back for more.
      </p>
    </section>
  );
}

export default AboutSection;
