import { useState } from "react";
import { BsInstagram } from "react-icons/bs";

function Header() {
  const [showing, setShowing] = useState(false);

  const scrollToSection = (section) => {
    const targetSection = document.getElementById(section);
    const headerHeight = document
      .querySelector("header")
      .getBoundingClientRect().height;
    setShowing(false);
    window.scrollTo({
      top: targetSection.offsetTop - headerHeight,
      behavior: "smooth",
    });
  };

  return (
    <header id="site-title">
      <h1>Flight 467</h1>

      <nav>
        <a
          href="https://www.instagram.com/flight467music"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsInstagram aria-label="Instagram"></BsInstagram>
        </a>
        <button
          id="hamburger"
          onClick={() => {
            setShowing(!showing);
          }}
          aria-label="show and hide menu"
        >
          <div id="menu1" className="hamburger"></div>
          <div id="menu2" className="hamburger"></div>
          <div id="menu3" className="hamburger"></div>
        </button>
        <ul id="nav" className={`${showing ? "showing" : ""}`}>
          <li>
            <button onClick={() => scrollToSection("about")}>About</button>
          </li>
          <li>
            <button onClick={() => scrollToSection("video")}>Video</button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
